import React, { Component, Fragment } from "react";
import Notification from "react-bulma-notification";
import endpoint from "../../helpers/Endpoint";

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      // password: "medgig-xedti2-Pomxak",
      code: "",
      style1: "",
      style2: ""
    };
  }

  openNotification = (type, msg) => {
    Notification[type](<strong>{msg}</strong>, { duration: 5 });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  forgotPasswordDialogue = () => {
    this.setState({ style1: "is-active" });
  };

  closeModal = () => {
    this.setState({ style1: "", style2: "" });
  };

  forgotPassword = (e) => {
    e.preventDefault();
    const { email } = this.state;
    const data = JSON.stringify({ email });
    console.log(data);
    console.log("test");
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    fetch(`${endpoint}/admin/forgot-password`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log(result);
          this.openNotification("error", result.message);
        } else {
          console.log(result);
          this.setState({ style1: "", style2: "is-active" });
          this.openNotification("success", result.message);
        }
      })
      .catch((error) => console.log(error));
  };

  recoverPassword = (e) => {
    e.preventDefault();
    const { code, password, email } = this.state;
    const data = JSON.stringify({ code, password, email });
    console.log(data);
    console.log("test");
    const token = localStorage.getItem("token");
    function handleErrors(result) {
      if (!result.ok) {
        throw Error(result.status);
      }
      return result;
    }
    fetch(`${endpoint}/admin/recover-password`, {
      method: "POST",
      body: data,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token
      }
    })
      .then(handleErrors)
      .then((result) => result.json())
      .then((result) => {
        if (result.status === "failure") {
          console.log(result);
          this.openNotification("error", result.data);
        } else {
          console.log(result);
          this.setState({ style2: "", email: "", password: "" });
          this.openNotification("success", result.message);
        }
      })
      .catch((error) => console.log(error));
  };

  render() {
    const { email, password, style1, style2 } = this.state;
    return (
      <Fragment>
        <form
          onSubmit={(e) => this.props.login(e, email, password)}
          className="mainLoginPage"
        >
          <div className="columns is-centered login-form">
            <div className="column is-one-third">
              <div className="card">
                <div className="card-content">
                  <div className="field">
                    <label className="label">Email</label>
                    <p className="control has-icons-left has-icons-right">
                      <input
                        className="input"
                        type="email"
                        placeholder="Email"
                        name="email"
                        onChange={this.handleChange}
                        value={email}
                        required
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-envelope" />
                      </span>
                      <span className="icon is-small is-right" />
                    </p>
                  </div>
                  <div className="field">
                    <label className="label">Password</label>
                    <p className="control has-icons-left">
                      <input
                        className="input"
                        type="password"
                        placeholder="Password"
                        name="password"
                        onChange={this.handleChange}
                        value={password}
                        required
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-lock" />
                      </span>
                    </p>
                  </div>
                  <div className="field">
                    <p className="control" >
                      <button className="button is-success" style={{marginBottom:5, marginTop:5, width:'100%',fontSize:16, fontWeight:600}}>Login</button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div className={`modal ${style1}`}>
          <div className="modal-background" />
          <div className="modal-content" style={{ zIndex: 1 }}>
            <div className="tile is-parent">
              <article className="tile is-child notification ">
                <div className="content">
                  <form onSubmit={this.forgotPassword}>
                    <div className="field">
                      <label className="label">Email Address</label>
                      <div className="control">
                        <input
                          className="input"
                          type="email"
                          name="email"
                          placeholder="Email Address"
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="control">
                      <button className="button is-primary" type="submit" style={{width:'100%'}}>
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </article>
            </div>
          </div>
          <div
            onClick={this.closeModal}
            style={{
              minWidth: "100%",
              minHeight: "100%",
              position: "absolute"
            }}
          >
            <button className="modal-close is-large" aria-label="close" />
          </div>
        </div>

        <div className={`modal ${style2}`}>
          <div className="modal-background" />
          <div className="modal-content" style={{ zIndex: 1 }}>
            <div className="tile is-parent">
              <article className="tile is-child notification ">
                <div className="content">
                  <form onSubmit={this.recoverPassword}>
                    <div className="field">
                      <label className="label">Code</label>
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          name="code"
                          placeholder="Code"
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="field">
                      <label className="label">New Password</label>
                      <div className="control">
                        <input
                          className="input"
                          type="password"
                          name="password"
                          placeholder="New Password"
                          onChange={this.handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="control">
                      <button className="button is-primary" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </article>
            </div>
          </div>
          <div
            onClick={this.closeModal}
            style={{
              minWidth: "100%",
              minHeight: "100%",
              position: "absolute"
            }}
          >
            <button className="modal-close is-large" aria-label="close" />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Login;
